import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Image, Structure, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Asvata Olare Bar
			</title>
			<meta name={"description"} content={"At Asvata Olare Bar, we are passionate about providing the ultimate sports-watching experience. "} />
			<meta property={"og:title"} content={"About | Asvata Olare Bar"} />
			<meta property={"og:description"} content={"At Asvata Olare Bar, we are passionate about providing the ultimate sports-watching experience. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/OGimage.png?v=2021-09-23T20:32:10.363Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/favicon.png?v=2021-09-23T20:33:18.314Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/32x32.png?v=2021-09-23T20:33:26.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/614ce40335c5c8001f7746e0/images/270.png?v=2021-09-23T20:33:32.819Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header />
		<Section>
			<Box min-width="100px" min-height="100px">
				<Text margin="0px 0px 0px 0px" font="--headline1">
					About us
				</Text>
				<Text margin="0px 0px 0px 0px" font="--lead">
					At Asvata Olare Bar, we are passionate about providing the ultimate sports-watching experience. Our mission is to create a welcoming environment where you can share great moments with friends. With a focus on top-notch entertainment, we offer a fantastic atmosphere to unwind and enjoy your favorite sports events. Learn more about our story and how we've become the best place in Delhi for having fun.{"\n\n"}
				</Text>
			</Box>
			<Link
				href="/contact-us"
				color="#000000"
				padding="8px 16px 8px 16px"
				text-decoration-line="initial"
				font="24px sans-serif"
				background="rgba(168, 168, 154, 0.81)"
				display="inline-block"
				align-self="flex-start"
				margin="30px 0px 20px 0px"
				border-radius="8px"
			>
				Contact us
			</Link>
			<Structure>
				<Override
					slot="Content"
					order="-1"
					display="grid"
					justify-content="center"
					min-width={0}
					width="0%"
				>
					<Override slot="cell-0">
						<Image
							src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12:45:33.603Z"
							display="block"
							width="400px"
							srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%282%29%20%281%29.jpg?v=2023-10-03T12%3A45%3A33.603Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Override>
					<Override slot="cell-1">
						<Image
							src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12:45:33.607Z"
							display="block"
							max-width="350px"
							srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/Best%20Pocket%20Friendly%20Bar%20Barshala%20Vikaspuri%20%285%29%20%281%29.JPG?v=2023-10-03T12%3A45%3A33.607Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Override>
					<Override slot="cell-2">
						<Image
							src="https://uploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12:45:33.577Z"
							display="block"
							max-width="400px"
							srcSet="https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/651c006edbe26f0020fbd967/images/staytunn-peenewalonkaparadise%20%287%29%20%282%29.jpg?v=2023-10-03T12%3A45%3A33.577Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Override>
					<Override slot="Cell 0th" width="30%" />
				</Override>
			</Structure>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});